import React, { useState, useEffect } from 'react';
import * as S from 'styles/Home/styled';
import { userAgent } from 'utils';
import { Placeholder } from 'components/Placeholder';

const Hero = () => {
  const [isSafari, setIsSafari] = useState(false);
  const HeroContainer = S.HeroContainer;

  const content =
    (
      <>
        <h1>
          Encontre as melhores vagas no <strong>mercado de tecnologia</strong>
        </h1>
        <h2>
          Confira vagas de tecnologia nas principais empresas do Brasil e
          candidate-se gratuitamente
        </h2>
      </>
    );

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      if (userAgent.isSafari) setIsSafari(true);
    }

    return () => (unmounted = true);
  }, []);

  return (
    <S.HeroSection>
      <HeroContainer safari={isSafari} title="vagas de tecnologia na vagas.com">
        {content}
      </HeroContainer>
    </S.HeroSection>
  );
};

export default Hero;
