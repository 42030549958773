import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const Placeholder = styled.div`
  animation: ${shimmer} 1300ms ease-in-out infinite;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-repeat: no-repeat;
  background-size: 200px 100%;
  border-radius: 4px;
  display: inline-block;
  height: ${({ height = '40px' }) => height};
  margin-bottom: 8px;
  margin-top: ${props => props.marginTop || '0'};
  width: ${({ width = '100%' }) => width};
`;
